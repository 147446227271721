import React from "react";

const Trading = () => {
  return (
    <div>
      "inital"
    </div>
  );
};

export default Trading;
