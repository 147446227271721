import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dummyPositions, updatePositions } from '../../../widgets/Position/data';

export const fetchPositions = createAsyncThunk(
  'positions/fetchPositions',
  async () => {
    return dummyPositions;
  }
);

export const updatePositionsData = createAsyncThunk(
  'positions/updatePositionsData',
  async () => {
    return updatePositions();
  }
);

const positionsSlice = createSlice({
  name: 'positions',
  initialState: {
    positions: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPositions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPositions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.positions = action.payload;
      })
      .addCase(fetchPositions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updatePositionsData.fulfilled, (state, action) => {
        state.positions = action.payload;
      });
  },
});

export default positionsSlice.reducer;