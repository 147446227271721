// 더미 포지션 데이터 생성 함수
const generateDummyPositions = () => {
  const symbols = ['AAPL', 'GOOGL', 'MSFT', 'AMZN', 'TSLA', 'FB', 'NFLX', 'NVDA', 'JPM', 'V', 'DIS', 'PYPL', 'ADBE', 'CRM', 'INTC'];
  const positions = [];

  symbols.forEach(symbol => {
    const quantity = Math.floor(Math.random() * 1000) + 1;
    const entryPrice = parseFloat((Math.random() * 1000 + 50).toFixed(2));
    const currentPrice = parseFloat((entryPrice * (1 + (Math.random() * 0.2 - 0.1))).toFixed(2));
    const pnlPercentage = ((currentPrice - entryPrice) / entryPrice * 100).toFixed(2);
    const pnl = pnlPercentage > 0 ? `+${pnlPercentage}%` : `${pnlPercentage}%`;

    positions.push({
      symbol,
      quantity,
      entryPrice,
      currentPrice,
      pnl,
      value: quantity * currentPrice // 포지션 가치 추가
    });
  });

  return positions;
};

// 더미 데이터 생성 및 전역 변수에 할당
export const dummyPositions = generateDummyPositions();

// 실시간 업데이트를 시뮬레이션하는 함수
export const updatePositions = () => {
  return dummyPositions.map(position => {
    const priceChange = position.currentPrice * (Math.random() * 0.02 - 0.01);
    const newCurrentPrice = parseFloat((position.currentPrice + priceChange).toFixed(2));
    const newPnlPercentage = ((newCurrentPrice - position.entryPrice) / position.entryPrice * 100).toFixed(2);
    const newPnl = newPnlPercentage > 0 ? `+${newPnlPercentage}%` : `${newPnlPercentage}%`;

    return {
      ...position,
      currentPrice: newCurrentPrice,
      pnl: newPnl,
      value: position.quantity * newCurrentPrice
    };
  });
};
