import React, { useRef, useEffect, useState } from 'react';
import './Info.scss';
import { FaYoutube } from 'react-icons/fa'; // YouTube 아이콘을 위해 react-icons 사용

const Info = () => {
  const containerRef = useRef(null);
  const [thumbnailSize, setThumbnailSize] = useState(60);

  useEffect(() => {
    const updateThumbnailSize = () => {
      if (containerRef.current) {
        const height = containerRef.current.clientHeight;
        setThumbnailSize(Math.max(height * 0.8, 40)); // 최소 크기 40px
      }
    };

    updateThumbnailSize();
    window.addEventListener('resize', updateThumbnailSize);

    return () => window.removeEventListener('resize', updateThumbnailSize);
  }, []);

  return (
    <div className="info-container" ref={containerRef}>
      <div className="info-content">
        <div 
          className="stock-thumbnail-wrapper"
          style={{ 
            width: `${thumbnailSize}px`, 
            height: `${thumbnailSize}px`,
            borderRadius: `${thumbnailSize / 2}px`
          }}
        >
          <div 
            className="stock-thumbnail"
            style={{ 
              width: `${thumbnailSize - 4}px`, 
              height: `${thumbnailSize - 4}px`
            }}
          ></div>
        </div>
        <div className="stock-info">
          <div className="stock-name-row">
            <h1 className="stock-name">ItSUB 잇섭</h1>
            <FaYoutube className="platform-icon" />
            <span className="stock-ticker">ITSUB</span>
            <div className="hashtags">
              <span className="hashtag">#IT</span>
              <span className="hashtag">#돼지</span>
              <span className="hashtag">#200만</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;