import React, { useRef, useEffect, useState } from "react";
import './Chart.scss';
import ChartComponent from "./ChartComponent/CahrtComponent";
import initialData from "./ChartComponent/Data";

function Chart() {
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (!containerRef.current) return;

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                setContainerSize({ width, height });
            }
        });

        resizeObserver.observe(containerRef.current);

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, []);

    return (
        <div ref={containerRef} className="chart-container">
            <ChartComponent data={initialData} />
        </div>
    );
}

export default Chart;