import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPositions, updatePositionsData } from '../../shared/redux/slices/PositionSlice';
import './Position.scss';

const MIN_HEIGHT = 30; // 최소 높이 설정
const TOP_POSITIONS_COUNT = 3; // 상위 포지션 개수
const TOP_POSITIONS_HEIGHT_RATIO = 0.6; // 상위 포지션이 차지할 전체 높이의 비율

const Position = () => {
  const dispatch = useDispatch();
  const { positions, isLoading, error } = useSelector((state) => state.positions);
  const [containerHeight, setContainerHeight] = useState(0);

  // 포지션 데이터 초기 로드 및 주기적 업데이트
  useEffect(() => {
    dispatch(fetchPositions());
    const interval = setInterval(() => {
      dispatch(updatePositionsData());
    }, 500); // 0.5초마다 업데이트
    return () => clearInterval(interval);
  }, [dispatch]);

  // 컨테이너 높이 측정 및 업데이트
  useEffect(() => {
    const updateContainerHeight = () => {
      const container = document.querySelector('.position-container');
      if (container) {
        setContainerHeight(container.clientHeight);
      }
    };
    updateContainerHeight();
    window.addEventListener('resize', updateContainerHeight);
    return () => window.removeEventListener('resize', updateContainerHeight);
  }, []);

  // 포지션 정렬 (가치 기준 내림차순)
  const sortedPositions = useMemo(() => {
    return [...positions].sort((a, b) => (b.quantity * b.currentPrice) - (a.quantity * a.currentPrice));
  }, [positions]);

  // 포지션 높이 계산
  const positionsWithHeight = useMemo(() => {
    if (containerHeight === 0) return sortedPositions;

    const totalHeight = containerHeight - 40; // 헤더 높이 제외
    const topPositionsHeight = totalHeight * TOP_POSITIONS_HEIGHT_RATIO;
    const remainingHeight = totalHeight - topPositionsHeight;

    const topPositionsValue = sortedPositions.slice(0, TOP_POSITIONS_COUNT)
      .reduce((sum, pos) => sum + pos.quantity * pos.currentPrice, 0);

    return sortedPositions.map((position, index) => {
      if (index < TOP_POSITIONS_COUNT) {
        const ratio = (position.quantity * position.currentPrice) / topPositionsValue;
        const height = Math.max(ratio * topPositionsHeight, MIN_HEIGHT);
        return { ...position, height };
      } else {
        const defaultHeight = remainingHeight / (sortedPositions.length - TOP_POSITIONS_COUNT);
        return { ...position, height: defaultHeight };
      }
    });
  }, [sortedPositions, containerHeight]);

  if (isLoading) return <div>로딩 중...</div>;
  if (error) return <div>오류: {error}</div>;

  // 포지션 목록 렌더링
  return (
    <div className="position-container">
      <div className="position-header">
        <span>심볼</span>
        <span>수량</span>
        <span>진입가</span>
        <span>현재가</span>
        <span>손익</span>
      </div>
      <div className="position-list">
        {positionsWithHeight.map((position, index) => (
          <PositionItem 
            key={position.symbol} 
            position={position} 
            isTopThree={index < TOP_POSITIONS_COUNT}
          />
        ))}
      </div>
    </div>
  );
};

// 개별 포지션 아이템 컴포넌트
const PositionItem = ({ position, isTopThree }) => {
  const pnlValue = parseFloat(position.pnl);
  const isProfitable = pnlValue >= 0;

  return (
    <div 
      className={`position-item ${isProfitable ? 'profitable' : 'loss'} ${isTopThree ? 'top-three' : ''}`}
      style={{ height: `${position.height}px` }}
    >
      <span>{position.symbol}</span>
      <span>{position.quantity}</span>
      <span>₩{position.entryPrice.toFixed(2)}</span>
      <span>₩{position.currentPrice.toFixed(2)}</span>
      <span>{position.pnl}</span>
    </div>
  );
};

export default Position;