import React from 'react'
import './Footer.scss'

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-left">
                    <span>© 2024 ustock</span>
                </div>
                <div className="footer-right">
                    <a href="/privacy">개인정보처리방침</a>
                    <a href="/terms">이용약관</a>
                    <a href="/founder">Founder's Statement</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer