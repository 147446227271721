/* eslint-disable*/
import './App.scss';
import { Routes, Route, Link } from 'react-router-dom'

import Main from '../modules/Main/Main';
import Test from '../modules/Test/Test';
function App() {


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Test />}></Route>
        <Route path="/test" element={<Main />}></Route>
      </Routes>
    </div>
  );
}


export default App;
