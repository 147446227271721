import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { login } from '../../shared/redux/slices/AuthSlice';
import { FaGoogle, FaFacebook, FaTwitter } from 'react-icons/fa';
import './Login.scss';

const LoginSchema = Yup.object().shape({
  username: Yup.string().required('아이디를 입력해주세요'),
  password: Yup.string().required('비밀번호를 입력해주세요'),
});

function Login({ onLoginSuccess, onClose }) {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, error, isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleSubmit = async (values) => {
    try {
      await dispatch(login(values)).unwrap();
      if (isLoggedIn) {
        onLoginSuccess();
      }
    } catch (err) {
      // 에러 처리
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300); // 애니메이션 종료 후 모달 닫기
  };

  return (
    <div className={`login-modal-content ${isVisible ? 'visible' : ''}`}>
      <Formik 
        initialValues={{ username: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form className="login-form">
            <button type="button" className="close-button" onClick={handleClose}>×</button>
            <h2>로그인</h2>
            <div className="input-group">
              <Field name="username" placeholder="아이디" className="input-field" />
              {errors.username && touched.username ? <div className="error-message">{errors.username}</div> : null}
            </div>
            <div className="input-group">
              <Field name="password" type="password" placeholder="비밀번호" className="input-field" />
              {errors.password && touched.password ? <div className="error-message">{errors.password}</div> : null}
            </div>
            <button type="submit" className="login-button" disabled={isLoading}>
              {isLoading ? '로그인 중...' : '로그인'}
            </button>
            {error && <div className="error-message">{error}</div>}
            <div className="social-login">
              <button type="button" className="social-button google">
                <FaGoogle />
              </button>
              <button type="button" className="social-button facebook">
                <FaFacebook />
              </button>
              <button type="button" className="social-button twitter">
                <FaTwitter />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Login;
