const initialData = [];

const startDate = new Date('2021-01-01');
const endDate = new Date('2023-12-31');
let currentDate = new Date(startDate);
let baseValue = 100;
let monthTrend = 0;

while (currentDate <= endDate) {
  // 매월 초에 새로운 트렌드 설정
  if (currentDate.getDate() === 1) {
    monthTrend = (Math.random() - 0.5) * 20; // -10% ~ +10% 월간 트렌드
  }

  // 일별 변동
  const dailyChange = (Math.random() - 0.5) * 4; // -2% ~ +2% 일별 변동
  
  // 월간 트렌드와 일별 변동을 합산
  baseValue += baseValue * (monthTrend / 100 / 30 + dailyChange / 100);

  initialData.push({
    time: currentDate.toISOString().split('T')[0],
    value: parseFloat(baseValue.toFixed(2))
  });

  currentDate.setDate(currentDate.getDate() + 1);
}

export default initialData;