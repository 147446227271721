import { createChart, ColorType } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';
// import './Chart.scss'

export const ChartComponent = props => {
    const {
        data,
        colors: {
            backgroundColor = 'white',
            lineColor = '#2962FF',
            textColor = 'black',
            areaTopColor = 'rgba(41, 98, 255, 0.4)',
            areaBottomColor = 'rgba(41, 98, 255, 0.1)',
        } = {},
    } = props;

    const chartContainerRef = useRef();
    const chartRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const { current: container } = chartContainerRef;
            const { current: chart } = chartRef;

            if (container && chart) {
                const newRect = container.getBoundingClientRect();
                chart.applyOptions({ width: newRect.width, height: newRect.height });
            }
        };

        const chart = createChart(chartContainerRef.current, {
            layout: {
                background: { type: ColorType.Solid, color: backgroundColor },
                textColor,
            },
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
        });

        chartRef.current = chart;

        chart.timeScale().fitContent();

        const newSeries = chart.addAreaSeries({
            lineColor,
            topColor: areaTopColor,
            bottomColor: areaBottomColor
        });
        newSeries.setData(data);

        const resizeObserver = new ResizeObserver(entries => {
            if (entries.length === 0 || entries[0].target !== chartContainerRef.current) {
                return;
            }
            const { width, height } = entries[0].contentRect;
            handleResize();
        });

        resizeObserver.observe(chartContainerRef.current);

        return () => {
            resizeObserver.disconnect();
            chart.remove();
        };
    }, [data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]);
    return (
        <div
            ref={chartContainerRef}
            style={{
                width: '100%',
                height: '100%',
            }}
        />
    );
};

export default ChartComponent;