import React, { useState } from "react";
import './Navbar.scss';

import { Input } from 'antd';
import Login from '../../widgets/Login/Login';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';


function Navbar(params) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

    ////// 임시 로그인 처리 시작 //////

    const handleLoginClick = () => {
        if (!isLoggedIn) {
            // 임시로 로그인 상태를 true로 변경
            setIsLoggedIn(true);
            // 실제 로그인 모달을 표시
            setIsLoginModalVisible(true);
        } else {
            setIsLoggedIn(false);
        }
    };

    // 실제 로그인 성공 처리 함수
    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
        setIsLoginModalVisible(false);
    };
    ////// 임시 로그인 처리 끝 //////

    return (
        <div className="Nav">
            <div className="logo">
                <span className="logo-text">uStock</span>
                <span className="beta-tag">beta</span>
            </div>
            <div className="search-container">
                <Input 
                    placeholder="종목입니다" 
                    className="custom-input"
                    // suffix는 입력 필드 오른쪽에 추가적인 컨텐츠를 표시하는 prop입니다.
                    // 여기서는 검색 버튼을 입력 필드 내부 오른쪽에 배치하는 데 사용됩니다.
                    suffix={    
                        <button className="search-button">
                            <SearchOutlined />
                        </button>
                    }
                />
                <button className="plus-button">
                    <PlusOutlined />
                </button>
            </div>
            <div className="nav-buttons">
                <button className="nav-button game-start-button">게임시작</button>
                <button 
                    className={`nav-button login-button ${isLoggedIn ? 'logged-in' : ''}`}
                    onClick={handleLoginClick}
                >
                    {isLoggedIn ? (
                        <img src="https://yt3.googleusercontent.com/ytc/AIdro_kutxZhVtnH4nWcW7ebuDER5TfHwPZJaqGyBVGjVC52A0A=s160-c-k-c0x00ffffff-no-rj"
                        alt="프로필" className="profile-image" />
                    ) : (
                        "로그인"
                    )}
                </button>
            </div>
            {isLoginModalVisible && (
                <div className="custom-modal">
                    <Login onLoginSuccess={handleLoginSuccess} onClose={() => setIsLoginModalVisible(false)} />
                </div>
            )}
        </div>
    )
}

export default Navbar;
