import React, { useState, useEffect, useRef, useCallback } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

// 그리드 디자인 수정
import "./css/_grid_style.css";
import "./css/grid_style.scss";
import "./css/_resizeable_style.css";

import gridProps from "./Settings.js";
import Layouts from "./Layouts.js";

// 컴포넌트 임포트
import Info from "../../widgets/Info/Info";
import Sign from "../../widgets/Sign/Sign";
import Chart from "../../widgets/Chart/Chart";
import Subinfo from "../../widgets/Subinfo/Subinfo";
import Position from "../../widgets/Position/Position";
import Account from "../../widgets/Account/Account";
import Trading from "../../widgets/Trading/Trading";

const GridLayout = WidthProvider(Responsive);

function Grid() {
  const [layout, setLayout] = useState(Layouts.lg);
  const [gridDimensions, setGridDimensions] = useState({ width: 0, height: 0 });
  const [dynamicRowHeight, setDynamicRowHeight] = useState(30);
  const gridRef = useRef(null);

  const calculateRowHeight = useCallback((height) => {
    return (height -5) / 30;
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        const newRowHeight = calculateRowHeight(height);
        setGridDimensions({ width, height });
        setDynamicRowHeight(newRowHeight);
        console.log("Totalheight:", height);
        console.log("Cell Dynamic Height:", newRowHeight);
        console.log("")
      }
    });

    if (gridRef.current) {
      resizeObserver.observe(gridRef.current);
    }

    return () => {
      if (gridRef.current) {
        resizeObserver.unobserve(gridRef.current);
      }
    };
  }, [calculateRowHeight]);

  const onLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  const renderComponent = (item) => {
    switch (item.i) {
      case 'a':
        return { component: <Info />, name: '<정보>' };
      case 'b':
        return { component: <Sign />, name: '<전광판>' };
      case 'c':
        return { component: <Chart />, name: '<차트>' };
      case 'd':
        return { component: <Subinfo />, name: '<정보>' };
      case 'e':
        return { component: <Trading />, name: '<트레이딩>' };
      case 'f':
        return { component: <Position />, name: '<포지션>' };
      case 'g':
        return { component: <Account />, name: '<계좌>' };
      default:
        return { component: null, name: 'Unknown' };
    }
  };

  return (
    <div ref={gridRef} style={{ width: '100%', height: '100%' }}>
      <GridLayout
        {...gridProps}
        layouts={Layouts}
        onLayoutChange={onLayoutChange}
        draggableHandle=".drag-handle"
        rowHeight={dynamicRowHeight}
      >
        {layout.map((item) => {
          const { component, name } = renderComponent(item);
          const itemHeight = item.h * dynamicRowHeight; // 아이템의 높이 계산

          return (
            <div key={item.i} className="grid-item">
              <div className="drag-handle">
                <span className="drag-handle-text">{name}</span>
                <span className="item-info">
                  {`${item.i.toUpperCase()} | W,H:${item.w}x${item.h} | X,Y:${item.x},${item.y} | ${itemHeight.toFixed(2)}px`}
                </span>
              </div>
              <div className="component-container">
                {component}
              </div>
            </div>
          );
        })}
      </GridLayout>
    </div>
  );
}

export default Grid;