import React from 'react';
import './Subinfo.scss';

const Subinfo = () => {
  return (
    <div className="subinfo-container">
      <div className="subinfo-header">
        <div className="stock-thumbnail-wrapper">
          <div className="stock-thumbnail"></div>
        </div>
        <div className="stock-ticker">ITSUB</div>
      </div>
      <div className="divider"></div>
      <div className="stock-description">
        Apple Inc. designs, manufactures, and markets smartphones, personal computers, tablets, wearables, and accessories worldwide.
      </div>
      <div className="info-grid">
        <div className="info-item">
          <h3>시가총액</h3>
          <p>2.5조 원</p>
        </div>
        <div className="info-item">
          <h3>52주 최고</h3>
          <p>180,000원</p>
        </div>
        <div className="info-item">
          <h3>52주 최저</h3>
          <p>90,000원</p>
        </div>
        <div className="info-item">
          <h3>거래량</h3>
          <p>1,234,567주</p>
        </div>
      </div>
    </div>
  );
};

export default Subinfo;
