// layouts.js
const Layouts = {
  xxl: [
    { i: 'a', x: 1, y: 0, w: 48, h: 2 },
    { i: 'b', x: 0, y: 2, w: 48, h: 1 },
    { i: 'c', x: 0, y: 3, w: 29, h: 15 },  // 가로 크기를 29로 변경
    { i: 'd', x: 29, y: 3, w: 10, h: 15 }, // 가로 크기를 10으로 변경, x 위치 조정
    { i: 'e', x: 39, y: 3, w: 9, h: 15 },  // 가로 크기를 9로 변경
    { i: 'f', x: 0, y: 18, w: 31, h: 10 },
    { i: 'g', x: 31, y: 18, w: 17, h: 10 },
  ],
  xl: [
    { i: 'a', x: 1, y: 0, w: 48, h: 2 },
    { i: 'b', x: 0, y: 2, w: 48, h: 1 },
    { i: 'c', x: 0, y: 3, w: 29, h: 15 },  // 가로 크기를 29로 변경
    { i: 'd', x: 29, y: 3, w: 10, h: 15 }, // 가로 크기를 10으로 변경, x 위치 조정
    { i: 'e', x: 39, y: 3, w: 9, h: 18 },  // 가로 크기를 9로 변경
    { i: 'f', x: 0, y: 18, w: 31, h: 10 },
    { i: 'g', x: 31, y: 18, w: 17, h: 10 },
  ],
  lg: [
    { i: 'a', x: 1, y: 0, w: 48, h: 2 },
    { i: 'b', x: 0, y: 2, w: 48, h: 1 },
    { i: 'c', x: 0, y: 3, w: 29, h: 15 },  // 가로 크기를 29로 변경
    { i: 'd', x: 29, y: 3, w: 10, h: 15 }, // 가로 크기를 10으로 변경, x 위치 조정
    { i: 'e', x: 39, y: 3, w: 9, h: 18 },  // 가로 크기를 9로 변경
    { i: 'f', x: 0, y: 18, w: 31, h: 10 },
    { i: 'g', x: 31, y: 18, w: 17, h: 10 },
  ],
  md: [
    // md 이하의 레이아웃은 변경하지 않았습니다.
    { i: 'a', x: 0, y: 0, w: 48, h: 2 },
    { i: 'b', x: 0, y: 2, w: 48, h: 1 },
    { i: 'c', x: 0, y: 3, w: 31, h: 23 },
    { i: 'd', x: 31, y: 3, w: 8, h: 23 },
    { i: 'e', x: 39, y: 3, w: 9, h: 23 },
    { i: 'f', x: 0, y: 26, w: 31, h: 8 },
    { i: 'g', x: 31, y: 26, w: 17, h: 8 },
  ],
  sm: [
    // sm 레이아웃은 변경하지 않았습니다.
    { i: 'a', x: 0, y: 0, w: 48, h: 2 },
    { i: 'b', x: 0, y: 2, w: 48, h: 1 },
    { i: 'c', x: 0, y: 3, w: 31, h: 23 },
    { i: 'd', x: 31, y: 3, w: 8, h: 23 },
    { i: 'e', x: 39, y: 3, w: 9, h: 23 },
    { i: 'f', x: 0, y: 26, w: 31, h: 8 },
    { i: 'g', x: 31, y: 26, w: 17, h: 8 },
  ],
  xs: [
    // xs 레이아웃은 변경하지 않았습니다.
    { i: 'a', x: 0, y: 0, w: 48, h: 2 },
    { i: 'b', x: 0, y: 2, w: 48, h: 1 },
    { i: 'c', x: 0, y: 3, w: 31, h: 23 },
    { i: 'd', x: 31, y: 3, w: 8, h: 23 },
    { i: 'e', x: 39, y: 3, w: 9, h: 23 },
    { i: 'f', x: 0, y: 26, w: 31, h: 8 },
    { i: 'g', x: 31, y: 26, w: 17, h: 8 },
  ],
  xxs: [
    // xxs 레이아웃은 변경하지 않았습니다.
    { i: 'a', x: 0, y: 0, w: 48, h: 2 },
    { i: 'b', x: 0, y: 2, w: 48, h: 1 },
    { i: 'c', x: 0, y: 3, w: 31, h: 23 },
    { i: 'd', x: 31, y: 3, w: 8, h: 23 },
    { i: 'e', x: 39, y: 3, w: 9, h: 23 },
    { i: 'f', x: 0, y: 26, w: 31, h: 8 },
    { i: 'g', x: 31, y: 26, w: 17, h: 8 },
  ],
};

export default Layouts;